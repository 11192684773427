type ClampValueInput = {
  value: number | null
  min: number
  max: number
}

export const clampValue = ({ value, min, max }: ClampValueInput): number => {
  if (!value || !Number.isFinite(value)) {
    return 0
  }

  return Math.min(Math.max(value, min), max)
}

export function getEnumKeyByValue<T extends { [index: string]: string }>(
  enumObj: T,
  value: T[keyof T]
): string {
  const result = Object.keys(enumObj).find(
    (key) => enumObj[key as keyof typeof enumObj] === value
  )

  if (!result) {
    throw new Error(`No key found for value: ${value}`)
  }

  return result
}

/**
 * Returns the actual enum value matching the provided string representation.
 * Eg. if we store an enum into local storage as a string, we need some way to get the enum value back
 *
 * ```
 * enum TestEnum {
 *   first = "FIRST VALUE",
 *   second = "SECOND VALUE"
 * }
 *
 * parseEnumValueFromString(TestEnum, "SECOND VALUE") // returns TestEnum.second
 * ```
 */
export function parseEnumValueFromString<T extends object>(
  enumType: T,
  value: string
): T[keyof T] | undefined {
  return Object.values(enumType).find((v) => v === value) as T[keyof T]
}

export function generateRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/**
 * Generates a unique name by appending an incrementing number if the default name is taken.
 * If existing names contain a number, the next available number is used.
 * Matching is case-insensitive for both the base name and numbered versions.
 *
 * @param existingNames - List of names that are already taken.
 * @param defaultName - The desired base name.
 * @return A unique name that is not in existingNames.
 */

export function generateUniqueName(
  existingNames: string[],
  defaultName: string
): string {
  const lowerBase = defaultName.toLowerCase()
  let isBaseTaken = false
  let maxSuffixNumber = 0

  existingNames.forEach((name) => {
    const lowerName = name.toLowerCase()

    // Check if the defaultName (case-insensitive) is used exactly
    if (lowerName === lowerBase) {
      isBaseTaken = true
    } else {
      // Check for numbered pattern, e.g. "Test 2" ignoring case
      const pattern = new RegExp(`^${lowerBase}\\s+(\\d+)$`)
      const match = lowerName.match(pattern)
      if (match && match[1]) {
        isBaseTaken = true
        const currentSuffixNumber = parseInt(match[1], 10)
        if (currentSuffixNumber > maxSuffixNumber) {
          maxSuffixNumber = currentSuffixNumber
        }
      }
    }
  })

  // If the base is not used and no numbered version exists, just return the base name
  if (!isBaseTaken && maxSuffixNumber === 0) {
    return defaultName
  }

  // If the base is used but no numbered version exists, start at 2
  if (isBaseTaken && maxSuffixNumber === 0) {
    return `${defaultName} 2`
  }

  // Otherwise, return the base name with maxSuffixNumber + 1
  return `${defaultName} ${maxSuffixNumber + 1}`
}
